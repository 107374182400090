.mythic-detail-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; /* Added for horizontal centering */
    padding: 20px;
    gap: 20px;
}

.mythic-image img {
    width: 100%;
    max-width: 400px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.mythic-info {
    display: flex;
    flex-direction: column;
    text-align: left;
    gap: 15px;
    width: 100%;
    max-width: 600px;
}

.mythic-title {
    font-size: 2rem;
    font-weight: bold;
    color: #333;
}

.mythic-rank, .mythic-description, .mythic-floor {
    font-size: 1.1rem;
    color: #555;
    margin: 5px;
}

/* Responsive Styling */
@media (min-width: 768px) {

    .mythic-title {
        margin: -10px 0 10px 0;
    }
    
    .mythic-detail-container {
        flex-direction: row;
        align-items: center; 
        justify-content: center;
        margin-top: 40px;
    }

    .mythic-image {
        flex: 1;
        max-width: 240px;
    }

    .mythic-info {
        flex: 2;
        padding-left: 20px;
    }
}