/* Footer.css */

.footer {
    background-color: #0c2446;
    color: white;
    padding: 20px 44px;
    text-align: center;
    flex-shrink: 0; /* Prevent the footer from shrinking */
  }
  .footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .footer-content p {
    margin: 0 auto;
  }
  
  .footer-links {
    display: flex;
    gap: 20px;
  }
  
  .footer-link {
    color: white;
    text-decoration: none;
  }
  
  .footer-link:hover {
    text-decoration: underline;
  }
  