/* src/Navbar.css */
.navbar {
    background-color: #022549; /* Mid-blue background for navbar */
    overflow: hidden;
    position: relative;
    top: 0;
    width: 100%;
    z-index: 1000;
    display: flex;
    justify-content: space-between; /* Align logo to the left and links to the right */
    align-items: center; /* Center items vertically */
}

.navbar .logo img {
    position: relative;
    height: 45px; /* Example height for your logo */
    padding: 15px 36px 20px 36px; /* Add padding around the logo */
    align: center;
}

.navbar a {
    float: right;
    display: block;
    color: #022549;
    text-align: center;
    padding: 16px 14px 14px 14px;
    text-decoration: none;
    transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transition for background color and text color */
}

.navbar-links {
    display: flex;
    padding: 0 36px;
    margin-top:-8px;
}

.navbar-links a {
    height: 100%;
    margin: 3px;
    background-color: #fff;
}

.navbar-links a:hover,
.navbar-links a.active {
    background-color: #f9d74b;
    color: #022549;
}

.navbar-links a:hover {
    background-color: #f9d74b;
    color: #022549;
}

.navbar-links a.active:hover {
    cursor: default;
}

.wallet-button {
    min-width: 120px;
    padding: 16px 5px 14px 5px !important;
    margin-right: 15px !important;
    margin-left: 12px !important;
    text-decoration: none;
    cursor: pointer;
    color: #ffffff;
    background-color: #8347e6!important;
    display: inline-block;
    border-radius: 25px;
    transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transition for background color and text color */
}

a.wallet-button {
    color: #ffffff;
    font-size: 0.9em;
    line-height: 1.1em;
}

a.wallet-button:hover,
a.wallet-button:active {
    background-color: #8347e6; /* Change to a slightly darker shade on hover/active */
    color: #ffffff; /* Change text color on hover/active */
}

@media (max-width: 860px) {
    .navbar {
        flex-direction: column;
        padding-bottom: 3px;
    }

    .navbar .logo {
        order: -1; /* Logo comes first on small screens */
    }

    .navbar-links {
        width: 100%;
        justify-content: space-around;
    }

    .navbar-links a {
        margin: 3px;
    }

    .navbar a {
        float: none;
        width: 100%;
        text-align: center; /* Align text to the left on small screens */
    }
    .wallet-button {
        display: none!important;
    }
}
