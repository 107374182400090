/* NotFoundPage.css */
.not-found-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    min-height: 50vh;
    padding: 20px;
  }
  
  .not-found-container img {
    max-width: 60%; /* Adjust based on your preference */
    margin-bottom: 20px;
  }
  
  .not-found-container h1 {
    margin-bottom: 10px;
  }
  
  .not-found-container p {
    margin-bottom: 20px;
  }
  