/* src/styles/MyCollection.css */

.my-collection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 60vh; /* Set height to fill the entire viewport */
  margin: 0; /* Remove default margin */
}

.connect-button {
  width: 200px; /* Adjust button width as needed */
  height: 50px; /* Adjust button height as needed */
  font-size: 18px; /* Adjust font size as needed */
  background-color: #8347e6; /* Background color for the button */
  color: #ffffff; /* Text color for the button */
  border: none; /* Remove button border */
  border-radius: 8px; /* Add border radius for button */
  cursor: pointer; /* Change cursor to pointer on hover */
}

.connect-button:hover {
  background-color: #6d3fb3; /* Darker background color on hover */
}

.connect-button:focus {
  outline: none; /* Remove focus outline */
}

/* Additional styles for footer */
.footer {
  background-color: #ffd50d;
  padding: 2vw 3vw 1.5vw 3vw !important;
  text-align: center;
  position: relative;
  left: 0;
  bottom: 0;
  width: 94vw;
}
