/* src/Mythics.css */
.mythics-container {
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
}
.loading {
    /* Add styles for loading indicator */
    font-size: 1em;
    color: #022549;
    padding-top: 25%;
} 
.mythics-rank {
    font-weight: bold;
    font-size: 1.5em;
} 
.mythics-name {
    font-size: 1em;
}

.mythics-minted a, .mythics-owned a {
    font-size: 1em;
    display: inline-block;
    width: 30px; /* Adjusted width for the circle */
    height: 30px; /* Adjusted height for the circle */
    background-color: #fff; /* Adjust the background color */
    color: #022549!important; /* Adjust the text color */
    text-align: center;
    line-height: 34px; /* Should match the height for vertical centering */
    text-decoration: none; /* Remove underline from the link */
    border-radius: 50%; /* Create a circle */
    margin: 0 5px; /* Adjust the margin */
    transition: background-color 0.3s ease; /* Smooth transition on hover */
}

td.mythics-minted a:hover, td.mythics-owned a:hover {
    background-color: #fff; /* Adjust the background color on hover */
    background-image: url('../images/opensea.png'); 
    background-size: cover; /* Ensure the image covers the entire circle */
    background-repeat: no-repeat; /* Prevent the image from repeating */
    color: transparent!important;
}

.mythics-image img {
    padding: 2.5px!important;
    max-width: 100%;
    height: auto;
    max-height: 64px;
}
.mythics-container th {
    border-top: 5px solid #ffd50d;
    border-bottom: 5px solid #ffd50d;
    background-color: #ffd50d;
    color: #022549;
}
.mythics-container td {
    border-bottom: 5px solid #ffd50d;
    background-color: #022549 !important;
    color: #fff;
    padding-top:5px;
    padding-bottom:0px;
    border-radius: 3px;
}
.mythics-container td a {
    color: #fff;
}
.unminted-row td {
    background-color: #DDE6F6 !important;
    
}
tr.highlight td {
    background-color: #8347e6!important; 
}
tr.not-yet td {
    background-color: #e2ad3b!important; 
    background-color: #022549!important;
    opacity: 0.3;
    color: #999;
}
tr.not-yet td.mythics-minted a:hover {
    color: transparent!important;
    background-color: #999;
}
tr.not-yet img {
    opacity: 0.5;
}
tr.not-yet td.normal-opacity span{
    color: rgba(255, 255, 255, 1)!important;
}
tr.not-yet td.normal-opacity span:hover {
    color: rgba(255, 255, 255, 1)!important;
}

@media (max-width: 600px) {
     
    .mythics-rank {
        font-weight: bold;
        font-size: 1em;
    } 
    .mythics-name {
        font-size: 0.8em;
    } 
    .mythics-minted a {
        font-size: 1em;
        width: 20px; /* Adjusted width for the circle */
        height: 20px; /* Adjusted height for the circle */
        line-height: 22px; /* Should match the height for vertical centering */
        margin: 0 5px; /* Adjust the margin */
    }
}