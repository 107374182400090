/* Stats.css */

.stats-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 75px;
  padding-top:2.5vh;
  padding-bottom:5vh;
}

.stat-container:last-child {
  margin-top: -25px;
}

.stat-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.stat-container > div {
  margin-top: 0;
}

/* Media query for tablets and larger screens */
@media (min-width: 1080px) {

  .stats-container {
    flex-direction: row; /* Display children horizontally */
    justify-content: center; /* Center children horizontally */
    align-items: center; /* Align children to the start vertically */
  }

  .stat-container {
    /* flex: 1; Remove this line */
    padding: 0 10px; /* Add some padding between charts */
    /* margin-top: 10vh; */
  }

  .stat-container > div {
    margin-top: 0; /* Reset margin top */
  }

}
