/* src/App.css */
/* App.css */
/* src/global.css */
html {
  background-color:#022549;
}
body {
  margin:0;
  padding: 0;
  display: flex;
  flex-direction: column;
  font-family: Arial, sans-serif;
  background-color: #ffd50d; /* Dark blue background */
  color: #022549; /* White text for better contrast */
}

#root {
  flex: 1;
  background-color:#022549;
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  /*min-height: 100vh;*/
}

.content {
  flex: 1; /* This will make the content expand to fill available space, pushing the footer down */
  padding-top:2.5vh;
  padding-bottom:5vh;
}

.footer {
  background-color: #0c2446;
  color: white;
  padding: 20px 44px;
  text-align: center;
  flex-shrink: 0; /* This ensures the footer doesn't shrink, but you had this already */
}

.container {
  margin: auto;
  padding: 1vw 3vw 3vw 3vw;
  background-color: #ffd50d; /* Dark blue background for container */
  /* border-radius: 10px; */
  margin-bottom: 20px;
  flex: 1; /* Allow the container to grow and take up remaining space */
  width: 94vw; /* Ensure the container fills the entire width */
  margin: 0 auto; /* Center the container horizontally */
}

.loading {
  /* Add styles for loading indicator */
  font-size: 18px;
  color: #022549;
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.privacy-policy {
  padding: 20px;
  margin: 0 auto;
}
.privacy-policy h2, .privacy-policy h3 {
  text-align:left;
  padding-top:20px;
}
.privacy-policy p {
  text-align:justify;
}

/* Any other styles you wish to add for responsiveness */
@media (max-width: 600px) {
  .container {
      padding: 0 10px;
  }
}
