/* src/Leaderboard.css */
.Leaderboard {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    margin: 0;
    width: 100%;
}

.table-container {
    width: 100%;
    overflow-x: auto;
    background-color: #ffd50d; /* Match the background color of Mythics */
    border-radius: 8px;
}

table {
    margin: 0 auto;
    border-spacing: 0;
    border-collapse: collapse;
    width: 99%;
    color: #022549; /* Match the text color of Mythics */
}

tr {
    border-radius: 3px;
}

th, td {
    border-bottom: 5px solid #ffd50d;
    background-color: #022549 !important;
    color: #fff;
    padding-top:18px;
    padding-bottom:15px;
}

th.position {
    min-width: 40px;
}

tr.highlight td {
    background-color: #8347e6!important; 
}

td a {
    color: #022549; /* Match the text color of Mythics */
    text-decoration: none;
}

td a:hover {
    color: #ffd50d; /* Match the link hover color of Mythics */
    text-decoration: none;
}

th {
    border-top: 5px solid #ffd50d;
    border-bottom: 5px solid #ffd50d;
    background-color: #ffd50d!important;
    color: #022549;
    cursor: pointer;
}

th:first-child, td:first-child,
th:last-child, td:last-child {
    text-align: center;
}

tr:nth-child(even) {
    background-color: #ffd50d; /* Match the even row background color of Mythics */
}


@media (max-width: 768px) {
    th, td {
        padding: 8px 4px;
        border-bottom: 1px solid #ffd50d;
    }
    th {
        font-size: 0.7em;
        border-bottom: 1px solid #ffd50d;
    }
    td {
        font-size: 0.7em;
    }
    th.position {
        min-width: 10px;
    }
}


